import React, { useEffect, useState } from 'react';
import { Dropdown, Navbar } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import edusfere_logo from '../../../assets/images/images/header_logo.png';
import profile from '../../../assets/images/icons/person.png';
import dots from '../../../assets/images/icons/dots.svg';
import dots_hover from '../../../assets/images/icons/dots_hover.svg';
import {
  addClassShow,
  addFolderShow,
  confirmAdaptShow,
  curActivity,
  curClass,
  dashboardActivityFirst,
  dashboardActivityPage,
  dashboardLessonFirst,
  dashboardLessonPage,
  orgActivityInAdapt,
  selectedActivityInLesson,
  showUnverified,
  UserInfo,
} from '../../../store';
import { useRecoilState, useRecoilValue } from 'recoil';
import { MessageString } from '../../../models/defaults';
import HttpService from '../../../services/HttpServices';
import { ConfirmModal } from '../../modals/ConfirmModal';
import { AddClassModal } from '../../modals/AddClassModal';
import { AddFolderModal } from '../../modals/AddFolderModal';
import { VerifyUserModal } from '../../modals/VerifyUserModal';
import { ConfirmAdaptModal } from '../../modals/ConfirmAdaptModal';
import TokenService from '../../../services/TokenService';
import acsi_log from '../../../assets/images/icons/acsi_logo.svg';
const backend_baseUrl = process.env.REACT_APP_BACKEND_URL;
let elink: string | undefined | null = undefined;
export const MainHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [confirm, setconfirm] = useState(false);
  const [profileImg, setProfileImg] = useState(profile);
  const [userinfo, setUserInfo] = useRecoilState(UserInfo);
  const [showaddClass, setShowAddClass] = useRecoilState(addClassShow);
  const [showaddFolder, setShowAddFolder] = useRecoilState(addFolderShow);
  const [cur_class, set_cur_class] = useRecoilState(curClass);
  const [showVerified, setShowVerified] = useRecoilState(showUnverified);
  const [activityInLesson, setActivityInLesson] = useRecoilState(
    selectedActivityInLesson
  );
  const cActivity = useRecoilValue(curActivity);
  const [activity_page, set_activity_page] = useRecoilState(
    dashboardActivityPage
  );
  const [lesson_page, set_lesson_page] = useRecoilState(dashboardLessonPage);
  const [activity_first, set_activity_first] = useRecoilState(
    dashboardActivityFirst
  );
  const [lesson_first, set_lesson_first] = useRecoilState(dashboardLessonFirst);
  const [confirmAdapt, setconfirmAdaptShow] = useRecoilState(confirmAdaptShow);
  const orgActivity = useRecoilValue(orgActivityInAdapt);
  const resetParams = () => {
    set_cur_class('');
    setActivityInLesson(undefined);
  };
  const isEditState = () => {
    if (
      (location.pathname.indexOf('edit') > -1 ||
        location.pathname.indexOf('build/template') > -1 ||
        location.pathname.indexOf('build/lesson') > -1 ||
        location.pathname.indexOf('/build/Edit/Lesson') > -1 ||
        location.pathname.indexOf('build/adapt/detail') > -1) &&
      location.pathname.indexOf('/build/template/detail') !== 0
    )
      return true;
    else {
      if (
        location.pathname.indexOf('/build/template/detail/1') > -1 &&
        cActivity
      ) {
        return true;
      }
    }
    return false;
  };
  useEffect(() => {
    if (userinfo?.member_Profile_imgURL) {
      setProfileImg(
        `${backend_baseUrl}/download/${userinfo?.member_Profile_imgURL}`
      );
    }
  }, [userinfo]);
  const onGoBack = () => {
    setconfirm(false);
  };
  const onLink = (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    // return;
    if (isEditState()) {
      elink = ev.currentTarget.getAttribute('href');
      ev.preventDefault();
      setconfirm(true);
    }
  };
  return (
    <>
      <Navbar
        bg="white"
        expand="sm"
        fixed="top"
        className="main_header box_shadow"
      >
        <div className="d-flex justify-content-between w-100 px-3">
          <div className="d-flex flex-fill">
            <div className='d-flex'>
              <Link
                to={userinfo ? '/dashboard' : '/'}
                className="d-flex log"
                onClick={() => {
                  set_activity_page(1);
                  set_lesson_page(1);
                  set_activity_first(true);
                  set_lesson_first(true);
                }}
              >
                <img
                  src={edusfere_logo}
                  alt=""
                  height={52}
                  className="my-auto"
                />
                {/* <span className="text-body align-text-bottom">(beta)</span> */}
              </Link>
              <Link
                to="/dashboard"
                className={`m_link dashboard ${
                  location.pathname.indexOf('/dashboard') === 0 ? 'active' : ''
                }`}
                onClick={onLink}
              >
                <span>home</span>
              </Link>
            </div>
            {/* <Link
              to='/find'
              className={`m_link find ${
                location.pathname.indexOf('/find') === 0 ? 'active' : ''
              }`}
              onClick={onLink}
            >
              <span>find</span>
            </Link> */}
            {/* <Link
              to='/adapt'
              className={`m_link adapt ${
                location.pathname === '/adapt' ||
                location.pathname.indexOf('/build/adapt/detail') === 0
                  ? 'active'
                  : ''
              }`}
              onClick={onLink}
            >
              <span>adapt</span>
            </Link> */}
            {/* <Link
              to='/build'
              className={`m_link build ${
                location.pathname.indexOf('/build') === 0 &&
                location.pathname.indexOf('/adapt') < 0
                  ? 'active'
                  : ''
              }`}
              onClick={onLink}
            >
              <span>build</span>
            </Link> */}
            {/* <Link
              to='/community'
              className={`m_link community ${
                location.pathname === '/community' ? 'active' : ''
              }`}
              onClick={onLink}
            >
              <span>community</span>
            </Link> */}
            <Link
              to="/help"
              className={`m_link help ${
                location.pathname === '/help' ? 'active' : ''
              }`}
              onClick={onLink}
            >
              <span>help</span>
            </Link>
          </div>
          <div className='me-2 d-flex'>
            <img src={acsi_log} alt="" className='align-self-center' height={50} />
          </div>
          <Dropdown>
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              className="header_profile text-body"
            >
              <div className="d-flex">
                <img
                  src={profileImg}
                  alt=""
                  className="profile rounded-circle"
                  height={50}
                />
                <h5 className="text-wrap text-start ps-1">
                  {userinfo?.member_FirstName} {userinfo?.member_LastName}
                </h5>
                <img
                  src={dots}
                  alt=""
                  height={7}
                  className="dots d-none"
                  onMouseOver={(e) => (e.currentTarget.src = dots_hover)}
                  onMouseOut={(e) => (e.currentTarget.src = dots)}
                />
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                <Link
                  to={`/profile/${userinfo?.member_Id}`}
                  className="text_not_decoration text-body"
                  onClick={onLink}
                >
                  view profile
                </Link>
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  if (userinfo) {
                    const memberId = userinfo.member_Id;
                    TokenService.updateLocalAccessToken('');
                    setUserInfo(undefined);
                    navigate('/', { replace: true });
                    HttpService.logout(memberId);
                  }
                }}
              >
                log out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Navbar>
      <ConfirmModal
        show={confirm}
        content={MessageString.discardText}
        leftTitle={'no'}
        rightTitle={'yes'}
        onOk={() => {
          onGoBack();
        }}
        onDiscard={async () => {
          if (cActivity && !cActivity.activity_Status) {
            await HttpService.deleteActivity(cActivity.activity_Id);
          }
          if (elink !== undefined && elink) {
            navigate(elink);
          }
          setconfirm(false);
          resetParams();
        }}
      />
      <AddClassModal
        show={showaddClass}
        onConfirm={() => setShowAddClass(false)}
        onOk={() => setShowAddClass(false)}
      />
      <AddFolderModal
        show={showaddFolder}
        onConfirm={() => {
          setShowAddFolder(false);
        }}
        onOk={() => {
          setShowAddFolder(false);
        }}
      />
      <VerifyUserModal
        show={showVerified}
        onOk={() => setShowVerified(false)}
      />
      <ConfirmAdaptModal
        show={confirmAdapt}
        orgActivity={orgActivity}
        onOk={() => setconfirmAdaptShow(false)}
        onAdapting={() => {
          setconfirmAdaptShow(false);
          navigate('/build/adapt/detail');
        }}
      />
    </>
  );
};
