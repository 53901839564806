import React from 'react';
import { Navbar } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import edusfere_logo from '../../../assets/images/images/header_logo.png';
import acsi_log from '../../../assets/images/icons/acsi_logo.svg';
interface Props {
  wrapClass?: string;
  modalLink?: boolean;
}
export const TopHeader = (props: Props) => {
  const navigate = useNavigate();
  const { wrapClass, modalLink } = props;
  return (
    <Navbar bg="white" expand="sm" fixed="top" className={wrapClass}>
      <div className="d-flex justify-content-between w-100 px-3">
        <div className="d-flex log">
          <Link to="/" className="d-flex">
            <img src={edusfere_logo} alt="" height={52} className="my-auto" />
            {/* <span className="text-body align-text-bottom">(beta)</span> */}
          </Link>
        </div>
        {!modalLink && (
          <div className="buttons d-flex align-items-end">
            <div className='me-2 d-flex'>
              <img src={acsi_log} alt="" className='align-self-center' height={50} />
            </div>
            <div className="pb-2">
              {/* <button
                className="signup"
                onClick={() => {
                  navigate('/signup');
                }}
              >
                Sign Up
              </button> */}
              <button
                className="login"
                onClick={() => {
                  navigate('/login', { replace: true });
                }}
              >
                Log In
              </button>
            </div>
          </div>
        )}
      </div>
    </Navbar>
  );
};
